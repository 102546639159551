import { For, Show, createResource, createMemo, createEffect } from "solid-js";
import { A, RouteSectionProps } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { users } from "solid-heroicons/outline";
import { debounce } from "@solid-primitives/scheduled";
import { useSessionContext, useCheckoutContext } from "~/utils/contexts";
import { TextFieldInput, SelectBox, SelectOption } from "~/components/inputs";
import { Accordion, Collapsible } from "~/components/ui";
import {
  CustomShipLocation,
  ShipWhenSelection,
  ProductSummary,
  CheckoutOrderSummary,
  LineDetails,
} from "~/components/ordering/checkout";
import { BaseLoader } from "~/components/utility";
import { facilityMapping } from "~/components/ordering/order-page";
import { InfoBox } from "~/components/utility";
import { type AccountAddress } from "~/services/roma-api/account/types";
import { PlantCodeKeys } from "~/services/roma-api/products/types";

export default function Review(props: RouteSectionProps) {
  const { session } = useSessionContext();
  const {
    cart,
    lines,
    checkoutData,
    setCheckoutData,
    isBPS,
    cannotPickupGFPF,
  } = useCheckoutContext();

  // TODO - refactor this into a createAsync using api fns
  const [shipping] = createResource(
    () => [isBPS(), checkoutData.CustomerID],
    async ([isBPS, id]) => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}${
            isBPS && id ? `/admin/customers/${id}/info` : "/account/profile"
          }`,
          {
            headers: {
              Authorization: `Bearer ${session()?.token}`,
            },
          }
        );
        const data = await response.json();
        if (data.ShipTos.length !== 0) {
          // This doesn't seem to be setting, or setting too early?
          // Used an effect below resource to set the AccountID on the checkoutData store
          setCheckoutData("shipToChoice", data.ShipTos[0].AccountID);
        }
        return data;
      } catch (err) {
        console.log(err);
      }
    }
  );

  // * MEMO
  // Note: the label for Custom must be maintained in the Dropdown - this is the only way currently to determine if the user has selected the 'CUSTOM' option.
  const shipOptions = createMemo(() => {
    if (shipping()) {
      let customShipCode = null;
      const list: { label: string; value: string; id: string | number } =
        shipping().ShipTos.map((item: AccountAddress, index: number) => {
          if (item.Name == "CUSTOM") {
            customShipCode = item.AccountID;
            return {
              value: index.toString(),
              label: "Ship to another address",
              id: item.AccountID,
            };
          }
          return {
            value: index.toString(),
            label: [
              item.Street,
              item.City,
              item.Region,
              item.Postal,
              item.Country,
            ].join(", "),
            id: item.AccountID,
          };
        });
      // list.push({label: `Pickup from ${cart.latest.DefaultPlant ? facilityMapping[cart.latest.DefaultPlant] + " ": ""}warehouse`, value: "PICKUP", id: "PICKUP"})
      return { customShipCode, list };
    } else {
      return { list: [] };
    }
  });

  const setOrderNotes = debounce((value: string) => {
    setCheckoutData("orderNotes", value);
  }, 200);

  createEffect(() => {
    if (shipping() && shipping().ShipTos.length !== 0) {
      setCheckoutData("shipToChoice", shipping().ShipTos[0].AccountID);
    }
  });

  return (
    <>
      <div class="md:col-span-8 flex flex-col gap-16 ">
        <div class="max-md:order-2  flex flex-col gap-6">
          {/* SHIPPING */}
          <div class="w-full bg-white px-6 py-8 rounded-sm space-y-8">
            <Show
              when={shipping()}
              fallback={<BaseLoader width="8" height="8" />}
            >
              <div class="text-xl">
                <Show when={isBPS() && checkoutData.CustomerID}>
                  {/* <Show when={true}> */}
                  <div class="bg-faint-blue flex items-center border rounded-md p-2 text-sm gap-2 mb-2 shadow-sm">
                    <Icon path={users} class="w-5 h-5 animate-pulse" />
                    <span>CHECKING OUT AS CUSTOMER:</span>
                  </div>
                </Show>
                <p class="font-bold">{shipping.latest.Company.FirstName}</p>
                <p class="font-light">
                  Account #{shipping.latest.Company.AccountID}
                </p>
              </div>
              <Show
                when={!cannotPickupGFPF()}
                fallback={
                  <InfoBox>
                    Pickup option is unavailable for Gallery / Photo frames. If
                    you have other items in your cart that you'd like to pick
                    up, please place a separate order.
                  </InfoBox>
                }
              >
                <SelectBox
                  label="Ship / Pickup"
                  options={[
                    { label: "Ship", value: "SHIP" },
                    {
                      label: `Pickup from ${
                        cart()?.DefaultPlant
                          ? facilityMapping[
                              cart()?.DefaultPlant as PlantCodeKeys
                            ] + " "
                          : ""
                      }warehouse`,
                      value: "PICKUP",
                    },
                  ]}
                  value={{ value: checkoutData.pickup ? "PICKUP" : "SHIP" }}
                  defaultValue="SHIP"
                  onChange={(option) => {
                    setCheckoutData("pickup", option.value === "PICKUP");
                  }}
                />
              </Show>
              <Show when={checkoutData.pickup === false}>
                <Show when={(shipOptions()?.list as []).length > 0}>
                  <SelectBox
                    label="Ship to"
                    options={shipOptions().list as SelectOption[]}
                    defaultValue="0"
                    onChange={(option) => {
                      setCheckoutData({
                        shipToChoice: option.id,
                        shipCustom: option.label == "Ship to another address",
                        pickup: option.value === "PICKUP",
                      });
                    }}
                  />
                </Show>
                <Show when={checkoutData.shipCustom === true}>
                  <CustomShipLocation />
                </Show>
                <ShipWhenSelection />
              </Show>
            </Show>
          </div>
          {/* END SHIPPING */}

          <main class="w-full bg-white px-6 py-8 rounded-sm">
            {/* PRODUCTS */}
            <h3 class="font-bold pb-4 text-lg">Your Order</h3>
            <div class="py-2">
              <h4 class="text-base">Products ({lines()?.products.length})</h4>
              <For each={lines()?.products}>
                {(line) => <ProductSummary line={line} />}
              </For>
            </div>
            {/* END PRODUCTS */}

            {/* SAMPLES */}
            <Show
              when={
                Array.isArray(lines()?.samples) && lines()?.samples.length! > 0
              }
            >
              <div class="py-4">
                <h4 class="text-base">Samples ({lines()?.samples.length})</h4>
                <For each={lines()?.samples}>
                  {(line) => <ProductSummary line={line} />}
                </For>
              </div>
            </Show>
            {/* END SAMPLES */}

            {/* ORDER NOTES */}
            <div class="mt-4">
              <Collapsible
                defaultOpen={true}
                name="orderNotes"
                trigger="Add Order Notes"
              >
                <div class="my-4">
                  <TextFieldInput
                    name="order-notes"
                    class="text-sm font-light"
                    type="textarea"
                    autoResize={true}
                    value={checkoutData.orderNotes}
                    onChange={(val) => setCheckoutData("orderNotes", val)}
                  />
                </div>
              </Collapsible>
            </div>
            {/* END ORDER NOTES */}

            {/* SUMMARY */}
            <div class="sm:w-1/2 ml-auto self-end pt-8 print:hidden">
              <CheckoutOrderSummary />
            </div>
            {/* END SUMMARY */}
          </main>

          {/* DISCLAIMER */}
          <div class="text-sm text-roma-dark-grey font-light">
            <ul class="list-outside list-disc ml-3">
              <li>
                To determine if you are eligible for the Freight Program, visit
                our{" "}
                <A href="#" class="text-roma-blue">
                  Terms & Conditions
                </A>{" "}
                page, or contact your Customer Care Representative
              </li>
              <li>Eligible discounts will appear upon invoicing</li>
            </ul>

            <p class="mt-6 italic">
              Please note all prices shown are net list price. Applicable taxes
              may apply. Your Items may ship separately according to lead times.
              When you click the 'Place Order' button, we will send you an
              e-mail acknowledging receipt of your order. By placing your order,
              you agree to RomaMoulding.com's privacy notice and conditions of
              use
            </p>
          </div>
          {/* END DISCLAIMER */}
        </div>
      </div>
    </>
  );
}
